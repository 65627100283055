import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  mergePosts,
  buildCanonical
} from '../lib/helpers'
import {loadNextPage} from '../lib/api'
import BlogPostPreview from '../components/post/preview'
import GraphQLErrorList from '../components/graphql/error'
import SEO from '../components/seo'
import Layout from '../components/layout'
import InfiniteScroll from 'react-infinite-scroller'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 9
      skip: 2
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              _id
              url
              fluid(maxWidth: 360) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
          authors {
            _key
            author {
              image {
                alt
                asset {
                  fluid(maxWidth: 48, maxHeight: 48) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              name
              slug {
                current
              }
            }
          }
          _rawCategories(resolveReferences: {maxDepth: 1000})
        }
      }
    }
    featured: allSanityPost(
      limit: 2
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              _id
              url
              fluid(maxWidth: 520) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
          authors {
            _key
            author {
              image {
                alt
                asset {
                  fluid(maxWidth: 48, maxHeight: 48) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              name
              slug {
                current
              }
            }
          }
          _rawCategories(resolveReferences: {maxDepth: 1000})
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props
  const postsPerPage = 6
  const [infinite, setInfinite] = useState({loading: false, page: 1, loadMore: false, hasMore: true})

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const featuredNodes = (data || {}).featured
    ? mapEdgesToNodes(data.featured)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  // adding new posts to local state
  const [featuredPosts] = useState(featuredNodes)
  const [posts, setPosts] = useState(postNodes)

  const loadNewPosts = () => {
    if (!infinite.loading && infinite.hasMore) {
      setInfinite({...infinite, loading: true, loadMore: true})
    }
  }

  useEffect(() => {
    if (infinite.loadMore && infinite.hasMore) {
      const startIndex = infinite.page * postsPerPage + (infinite.page === 1 ? 5 : 0)
      const endIndex = (startIndex + postsPerPage) - 1

      loadNextPage(startIndex, endIndex)
        .then(data => {
          const all = mergePosts(posts, data)
          setPosts(all)
          setInfinite({page: infinite.page + 1, loading: false, loadMore: false, hasMore: data.length > 0})
        })
        .catch((error) => {
          console.log(error)
          setInfinite({page: infinite.page, loading: false, loadMore: false, hasMore: false})
        })
    }
  })

  return (
    <Layout>
      <SEO
        canonical={buildCanonical('')}
        title={site.title}
        description={site.description}
        keywords={site.keywords} />
      <section>
        <div className='relative bg-gray-50 pt-8 pb-20 px-4 sm:px-6 lg:pt-8 lg:pb-28 lg:px-8'>
          <div className='relative max-w-7xl mx-auto container'>
            <div className='md:grid-cols-2 grid gap-5'>
              {featuredPosts.map(post => <BlogPostPreview key={post.id} post={post} />)}
            </div>
            <div className='flex relative'>
              <div className='w-3/3 md:w-full'>
                <InfiniteScroll
                  className='mt-12 grid gap-5 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:max-w-none'
                  pageStart={0}
                  loadMore={() => loadNewPosts()}
                  hasMore={!infinite.loading && infinite.hasMore}>
                  {posts.map(post => <BlogPostPreview key={post.id} post={post} />)}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
